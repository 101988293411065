// array with the names of the comunas of chile's metropolitan region
export const comunas: string[] = [
  "Antofagasta",
  "Alhue",
  "Buin",
  "Calera",
  "Calera de Tango",
  "Cerrillos",
  "Cerro Navia",
  "Chiguayante",
  "Colina",
  "Concepción",
  "Concón",
  "Conchalí",
  "Coquimbo",
  "Coronel",
  "Curacaví",
  "El Bosque",
  "El Monte",
  "Estación Central",
  "Florida",
  "Huechuraba",
  "Independencia",
  "Isla de Maipo",
  "La Cisterna",
  "La Florida",
  "La Granja",
  "La Pintana",
  "La Reina",
  "La Serena",
  "Lampa",
  "Las Condes",
  "Lo Barnechea",
  "Lo Espejo",
  "Lo Prado",
  "Lota",
  "Macul",
  "Maipú",
  "María Pinto",
  "Melipilla",
  "Ñuñoa",
  "Padre Hurtado",
  "Paine",
  "Pedro Aguirre Cerda",
  "Penco",
  "Peñaflor",
  "Peñalolén",
  "Pirque",
  "Providencia",
  "Pudahuel",
  "Puente Alto",
  "Quilicura",
  "Quillota",
  "Quilpué",
  "Quinta Normal",
  "Recoleta",
  "Renca",
  "San Bernardo",
  "San Joaquín",
  "San José de Maipo",
  "San Miguel",
  "San Pedro",
  "San Pedro de la Paz",
  "San Ramón",
  "Santiago",
  "Talagante",
  "Talcahuanco",
  "Til Til",
  "Tomé",
  "Valparaíso",
  "Villa Alemana",
  "Viña del Mar",
  "Vitacura",
];
