// React component with a form to create a new member of the health team
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import nurseService from "../../utils/api/v1/nurseService";
import { navigate } from "gatsby-link";
import { format, validate } from "rut.js";
import { comunas } from "../../utils/comunas/names";
import { validateEmail } from "../../utils/regex";
import estadoMunicipio from "../../utils/comunas/estado_municipio.json";

// typings
import { NewNurse } from "../../utils/interfaces/User";

// components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import Flex from "../../components/Containers/Flex";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import Swal from "sweetalert2";
import Wrapper from "../../components/Containers/Wrapper";
import SButton from "../../components/Buttons/SButton";
import Error from "../../components/Errors/Error";
import { Specialty } from "../../utils/interfaces/Nurse";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;

  ${(props: { margin?: string }) => {
    if (props.margin) {
      return `margin: ${props.margin};`;
    }
  }}

  .MuiFormControl-root {
    margin: 5px 10px;
  }
`;

const FixedFlex = styled(Flex)`
  max-width: 500px;

  .MuiChip-root {
    margin: 2px;
  }
`;

const CountrySelect = styled(Select)`
  width: 75px;
`;

const NewHealthTeamMember = (): JSX.Element => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("Chile");
  const [comunasOptions, setComunasOptions] = useState<string[]>(comunas);
  const [specialtyOptions, setSpecialtyOptions] = useState<string[]>([]);
  const [userData, setUserData] = useState<NewNurse>({
    email: "",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    phone: "",
    rut: "",
    date_of_birth: "",
    gender: "male",
    pcr: false,
    blood: false,
    comunas: [],
    fingerprint_available: false,
    country: 'cl',
    specialties: [],
  });

  useEffect(() => {
    const fetchNurseSpecialties = async () => {
      const res = await nurseService.fetchNurseSpecialties()
      const specialties = res.data.map((speciality: Specialty) => speciality.name)
      setSpecialtyOptions(specialties)
    }
    fetchNurseSpecialties()
  }, []);

  const createGenericIdMx = () => {
    const parsedBirthDate = userData.date_of_birth?.split('-').join('')
    const genericId = `${userData.first_name}${userData.last_name}${parsedBirthDate}`.toUpperCase();
    return genericId
  };

  const onSubmit = async () => {
    setLoading(true);
    const dataNewNurse = userData.country === "mx" ? { ...userData, rut: createGenericIdMx() } : userData;
    const errors = checkErrors(dataNewNurse);
    if (errors) {
      setLoading(false);
      return;
    }

    try {
      const req = await nurseService.createNurse(dataNewNurse);
      Swal.fire({
        icon: req.status === 204 ? "error" : "success",
        title:
          req.status === 204
            ? "Tomador con ese RUT ya existe en la base de datos"
            : "Tomador creado con éxito!",
        text: req.data.msg,
        showConfirmButton: true,
        didClose: () => {
          navigate(-1);
        },
      });
    } catch (err) {
      console.error(err);
      setErrors([err.message]);
    }

    setLoading(false);
  };

  const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const attribute = event.target.id;
    setUserData((prevState: NewNurse): NewNurse => {
      const newState = { ...prevState };
      newState[attribute] = attribute === "rut" ? format(newValue) : newValue;
      return newState;
    });
  };

  const changeCountry = (event): void => {
    setCountry(event.target.value);
    if (event.target.value === "Chile") {
      setComunasOptions(comunas);
      setUserData({ ...userData, country: "cl" });
    } else if (event.target.value === "México") {
      setUserData({ ...userData, country: "mx" });
      setComunasOptions([...estadoMunicipio["Ciudad de México"], ...estadoMunicipio["Estado de México"]]);
    }
  };

  const genderChange = (event): void => {
    setUserData((prevState: NewNurse): NewNurse => {
      const newState = { ...prevState };
      newState.gender = event.target.value;
      return newState;
    });
  };

  const checkErrors = (data): boolean => {
    const newErrors: string[] = [];

    // check for errors in the form
    if (data.first_name.length === 0) {
      newErrors.push("El nombre es obligatorio");
    }
    if (data.last_name.length === 0) {
      newErrors.push("El apellido es obligatorio");
    }
    if (data.country === "cl" && !validate(data.rut ? data.rut : "")) {
      newErrors.push("El RUT no es válido");
    }
    if (!validateEmail(data.email)) {
      newErrors.push("El email no es válido");
    }

    setErrors(newErrors);
    return newErrors.length ? true : false;
  };

  return (
    <PrivateRoute>
      <Flex justify="center">
        <Wrapper variant="outlined" >
          <h2>Crear nuevo miembro del HealthTeam</h2>

          <Error>
            <ul>
              {errors.map((i, key) => (
                <li key={key}>{i}</li>
              ))}
            </ul>
          </Error>

          <Row margin="1rem 0rem 0rem 0rem">
            <FormControl>
              <CountrySelect
                value={country}
                onChange={changeCountry}
              >
                {["Chile", "México"].map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </CountrySelect>
              <FormHelperText>País</FormHelperText>
            </FormControl>
            <FormControl fullWidth>
              <Select
                multiple
                fullWidth
                value={userData.comunas}
                onChange={(event) =>
                  setUserData((prevState: NewNurse): NewNurse => {
                    const newState = { ...prevState };
                    newState.comunas = event.target.value as string[];
                    return newState;
                  })
                }
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <FixedFlex wrap="wrap">
                    {(selected as string[]).map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </FixedFlex>
                )}
              >
                {comunasOptions.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{country === "Chile" ? "Comunas" : "Alcaldías"}</FormHelperText>
            </FormControl>
          </Row>
          <Row>
            <TextField
              id="first_name"
              onChange={formChange}
              value={userData.first_name}
              helperText="Primer nombre"
              fullWidth
            ></TextField>
            <TextField
              id="second_name"
              onChange={formChange}
              value={userData.second_name}
              helperText="Segundo nombre"
              fullWidth
            ></TextField>
          </Row>
          <Row>
            <TextField
              id="last_name"
              onChange={formChange}
              value={userData.last_name}
              helperText="Apellido"
              fullWidth
            ></TextField>
            <TextField
              id="second_last_name"
              onChange={formChange}
              value={userData.second_last_name}
              helperText="Segundo apellido"
              fullWidth
            ></TextField>
          </Row>
          <Row>
            <TextField
              id="email"
              onChange={formChange}
              value={userData.email}
              helperText="Email"
              fullWidth
            ></TextField>
          </Row>
          <Row>
            <TextField
              id="phone"
              onChange={formChange}
              value={userData.phone}
              helperText="Teléfono"
              fullWidth
            ></TextField>
            {country === "Chile" && <TextField
              id="rut"
              onChange={formChange}
              value={userData.rut}
              helperText="RUT"
              fullWidth
            ></TextField>}
          </Row>
          <Row>
            <FormControl fullWidth>
              <Select onChange={genderChange} value={userData.gender} fullWidth>
                <MenuItem value="male">Hombre</MenuItem>
                <MenuItem value="female">Mujer</MenuItem>
                <MenuItem value="other">Otro</MenuItem>
              </Select>
              <FormHelperText>Sexo</FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              id="date_of_birth"
              helperText="Fecha de nacimiento"
              type="date"
              onChange={formChange}
              value={userData.date_of_birth}
            />
          </Row>
          <Row>
            <FormControl fullWidth>
              <Select
                multiple
                fullWidth
                value={userData.specialties}
                onChange={(event) =>
                  setUserData((prevState: NewNurse): NewNurse => {
                    const newState = { ...prevState };
                    newState.specialties = event.target.value as string[];
                    return newState;
                  })
                }
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <FixedFlex wrap="wrap">
                    {(selected as string[]).map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </FixedFlex>
                )}
              >
                {specialtyOptions?.map((specialty) => (
                  <MenuItem key={specialty} value={specialty}>
                    {specialty}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Especialidades</FormHelperText>
            </FormControl>
          </Row>
          <Row margin="1rem 0rem 0rem 0rem">
            <FormControlLabel
              control={
                <Switch
                  name="PCR"
                  color="primary"
                  checked={userData.pcr}
                  onChange={() =>
                    setUserData((prevState: NewNurse): NewNurse => {
                      const newState = { ...prevState };
                      newState.pcr = !newState.pcr;
                      return newState;
                    })
                  }
                />
              }
              label="PCR"
            />
            <FormControlLabel
              control={
                <Switch
                  name="Sangre"
                  color="primary"
                  checked={userData.blood}
                  onChange={() =>
                    setUserData((prevState: NewNurse): NewNurse => {
                      const newState = { ...prevState };
                      newState.blood = !newState.blood;
                      return newState;
                    })
                  }
                />
              }
              label="Sangre"
            />
            <FormControlLabel
              control={
                <Switch
                  name="¿Tiene huellero?"
                  color="primary"
                  checked={userData.fingerprint_available}
                  onChange={() =>
                    setUserData((prevState: NewNurse): NewNurse => {
                      const newState = { ...prevState };
                      newState.fingerprint_available = !newState.fingerprint_available;
                      return newState;
                    })
                  }
                />
              }
              label="¿Tiene huellero?"
            />
          </Row>

          <Flex align="center" justify="center" margin="2rem 0rem 1rem 0rem">
            <SButton
              variant="outlined"
              color="secondary"
              disabled={loading}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </SButton>
            <SButton
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={onSubmit}
            >
              Crear
            </SButton>
          </Flex>
        </Wrapper>
      </Flex>
    </PrivateRoute>
  );
};

export default NewHealthTeamMember;
