import api from "../api";

import { NewNurse } from "../../interfaces/User";
import { Locale } from "../../interfaces/Locale";
import { NewAnnouncement } from "../../interfaces/Announcement";

const baseUrl = "nurses";

class NurseService {
  createNurse = (nurseData: NewNurse) => {
    return api.post(`${baseUrl}/`, nurseData);
  };

  updateNurse = (id: string, data: any, fromSectorsDashboard: boolean = false) => {
    return api.patch(`${baseUrl}/${id}/update_nurse/`, {
      ...data,
      from_sectors_dashboard: fromSectorsDashboard,
    });
  };

  patchNurse = (id: string, data: any) => {
    return api.patch(`${baseUrl}/${id}/`, data);
  };

  activateNurse = (id: string, data: any) => {
    return api.patch(`${baseUrl}/${id}/activate/`, data);
  };

  fetchNurseProfile = (nurseId: string) => {
    return api.get(`${baseUrl}/${nurseId}/`);
  };

  fetchActiveNurses = (locale?: Locale) => {
    return api.get(`${baseUrl}/active_nurses/`, {
      params: { country: locale },
    });
  };

  fetchByCommune = (commune) => {
    return api.get(`${baseUrl}/by_commune/`, {
      params: { commune: commune },
    });
  };

  fetchInactiveNurses = (locale?: Locale) => {
    return api.get(`${baseUrl}/inactive_nurses/`, {
      params: { country: locale },
    });
  };

  fetchHeatMap = (comunas = ["all"], serviceTypeValue = "all", hasFingerprint = "all", country) => {
    const params = {
      "range": 14,
      "service_type": serviceTypeValue,
      "fingerprint_available": hasFingerprint,
      "comunas": comunas,
      "country": country
    }
    return api.get(`nurse-blocktables/timeblocks/nurse_availabilities_heatmap/`, {params: params});
  };

  fetchAppointments = (nurseId: string, status: string) => {
    return api.get(
      `dashboard/appointments/?nurse_id=${nurseId}&status=${status}`
    );
  };

  uploadProfilePicture = (nurseId: string, formData: any) => {
    return api.post(`${baseUrl}/${nurseId}/upload_photo/`, formData);
  };

  fetchServices = (nurseId: string, category?: string) => {
    return api.get(`${baseUrl}/lab_services/`, {
      params: { nurse_id: nurseId, category: category },
    });
  };

  fetchLabsAndNExams = (nurseId: string) => {
    return api.get(`${baseUrl}/${nurseId}/labs/`);
  };

  fetchServicesCategories = (nurseId: string) => {
    return api.get(`${baseUrl}/${nurseId}/services_categories/`);
  };

  fetchNurseSpecialties = () => {
    return api.get("nurse-specialties/");
  };

  fetchNurseAnnouncements = (
    currentPage: number = 1,
    country_code: string = "cl"
  ) => {
    const params = {
      page: currentPage,
      country_code: country_code,
    };
    return api.get(`notification/announcements`, {
      params: params,
    });
  };

  uploadNurseAnnouncement = ({
    title,
    message,
    country_code,
    is_private,
    nurses,
  }: NewAnnouncement) => {
    const data = {
      title: title,
      message: message,
      country_code: country_code,
      target: "nurses",
      private: is_private,
      nurses: nurses,
    };
    return api.post(`notification/announcements/`, data);
  };

  deleteNurseAnnouncement = (announcementId: string) => {
    const data = {
      id: announcementId,
    };
    return api.delete(`notification/announcements/`, {data: data});
  };
}

const nurseService = new NurseService();
export default nurseService;
